import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Page/common/login";
import Dashboard from "./Page/Components/dashboard";
import TimeTracker from "./Page/Components/TimeTracker";
import "./App.css";
import PrivateRoute from "./Page/Components/PrivateRoute";
import NotFound from "./Page/Components/notFound";
import SedulingPage from "./Page/Components/sedulingPage";
import TimeClocking from "./Page/Components/timeClocking";
import TipMangements from "./Page/Components/TipMangements";
import TeamMangement from "./Page/Components/TeamMagement";
import Feature from "./Page/Components/Feature";
import ContactUs from "./Page/Components/ContactUs";
import NotificationList from "./Page/Components/NotificationList";
import AuthenticatedLayout from "./Page/Auth/AuthenticatedLayout";
import CalendarViewText from "./Page/Components/calenderView";
import Profile from "./Page/Components/Profile";
import OverTimeList from "./Page/Components/overTimeTable";
import Download from "./Page/Components/Download";
import About from "./Page/Components/About";
import TimeTrackerRequest from "./Page/Components/TimeTrackerRequest";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="login" element={<Login />} />
        <Route path="timeTracker" element={<PrivateRoute><AuthenticatedLayout><TimeTracker /></AuthenticatedLayout></PrivateRoute>} />
        <Route path="time-tracker-request" element={<PrivateRoute><AuthenticatedLayout><TimeTrackerRequest /></AuthenticatedLayout></PrivateRoute>} />
        <Route path="shiftTime" element={<PrivateRoute><AuthenticatedLayout><OverTimeList /></AuthenticatedLayout></PrivateRoute>} />
        <Route path="calendar" element={<PrivateRoute><AuthenticatedLayout><CalendarViewText /></AuthenticatedLayout></PrivateRoute>} />
        <Route path="profile" element={<PrivateRoute><AuthenticatedLayout><Profile /></AuthenticatedLayout></PrivateRoute>} />
        <Route path="notifications" element={<PrivateRoute><AuthenticatedLayout><NotificationList /></AuthenticatedLayout></PrivateRoute>} />
        <Route path="timeTracker" element={<PrivateRoute element={<TimeTracker />} />} />
        <Route path="sedulingPage" element={<SedulingPage />} />
        <Route path="timeClocking" element={<TimeClocking />} />
        <Route path="tipMangements" element={<TipMangements />} />
        <Route path="teamMangement" element={<TeamMangement />} />
        <Route path="feature" element={<Feature />} />
        <Route path="contactUs" element={<ContactUs />} />
        <Route path="Download" element={<Download />} />
        <Route path="about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
